body {
	font-family: 'Courier Prime', monospace;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 1.4;
	color: var(--color-text-1);

	--color-background: #fff;
	--color-text-1: #000;
	--color-text-grey-light: #adadad;
	--color-text-link: #119fff;
	--color-text-link-light: #b6e2ff;
	--color-text-red: #ff1111;
	--color-footer: #000;
	--color-grey: #d1d1d1;

	--shadow-nav: 0px 1px 14px rgba(15, 50, 105, 0.08);
	--shadow-1: 0 6px 20px #d0d6e2d7;
	--shadow-small: 0 4px 8px #d0d6e2d7;
	--shadow-dropdown: 0px 10px 8px rgba(0, 0, 0, 0.2);
	--shadow-planet: 0px 4px 5px rgba(0, 0, 0, 0.1);

	--transition-main: all 0.15s ease-in-out;
	--transition-long: all 0.25s ease-in-out;

	--font-size-title: 30px;
	--font-size-text: 18px;
	--font-size-small-text: 16px;

	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
}

h2 {
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
}

input {
	color: var(--color-text-grey-light);
	letter-spacing: normal;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	display: inline-block;
	text-align: start;
	cursor: text;
	margin: 0em;
	padding: 1px 2px;
	-o-border-image: initial;
	border-image: initial;
	font-family: 'Inter', sans-serif;
	outline: none;
	border: none;
}

::-webkit-input-placeholder {
	color: var(--color-text-grey-light);
}

::-moz-placeholder {
	color: var(--color-text-grey-light);
}

:-ms-input-placeholder {
	color: var(--color-text-grey-light);
}

::-ms-input-placeholder {
	color: var(--color-text-grey-light);
}

textarea {
	text-rendering: auto;
	color: var(--color-text-grey);
	letter-spacing: normal;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	display: inline-block;
	text-align: start;
	-webkit-appearance: auto;
	-moz-appearance: auto;
	appearance: auto;
	cursor: text;
	margin: 0em;
	padding: 1px 2px;
	-o-border-image: initial;
	border-image: initial;
	font-family: 'Inter', sans-serif;
	outline: none;
	border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	display: none;
}

::-webkit-scrollbar {
	width: 6px;
}
@media screen and (max-width: 950px) {
	::-webkit-scrollbar {
		width: 2px;
	}
}
::-webkit-scrollbar-track {
	background-color: var(--color-background);
}
::-webkit-scrollbar-thumb {
	background-color: var(--color-text-link-light);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: var(--color-text-link);
}
::-webkit-scrollbar-corner {
	background-color: var(--color-background);
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.App {
	width: 100%;
	min-height: 101vh;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
}

.section-title {
	font-size: var(--font-size-title);
	letter-spacing: 8px;
	text-align: center;
}

.section-desc {
	font-size: var(--font-size-small-text);
	font-weight: 500;
	font-family: 'Courier New', Courier, monospace;
	margin: 10px 0 40px 0;
	text-align: center;
}

@media screen and (max-width: 720px) {
	.section-desc {
		margin: 10px 0 20px 0;
	}
}

.text-link {
	text-decoration: underline;
	color: var(--color-text-1);
	transition: var(--transition-main);
}
.text-link:hover {
	text-decoration: underline;
	color: var(--color-text-link);
	transition: var(--transition-main);
}

.navbar {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	/* background-color: var(--color-background); */
	transition: var(--transition-long);
	box-shadow: none;
	z-index: 998;
}
.navbar.shadow {
	box-shadow: var(--shadow-nav);
	transition: var(--transition-long);
	background-color: var(--color-background);
}

.navbar-content {
	width: 100%;
	max-width: 1100px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 14px 0;
	z-index: 2;
}

.centered {
	justify-content: center;
}

.navbar-button {
	font-size: 16px;
	width: 100px;
	color: var(--color-text-1);
	transition: var(--transition-main);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.navbar-button:hover {
	transition: var(--transition-main);
	opacity: 0.5;
}

.navbar-logo-container {
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar-logo {
	height: 30px;
	width: auto;
	font-weight: 200;
	margin: 0px 20px;
	transition: var(--transition-long);
	cursor: pointer;
}
.navbar-logo.large {
	height: 40px;
	margin: 10px 20px;
	transition: var(--transition-long);
}

.navbar-toggle {
	display: none;
}

.blackBGNav {
	visibility: hidden;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 66px;
	left: 0;
	opacity: 0;
	transition: all 0.4s ease;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
.blackBGNav.active {
	visibility: visible;
	opacity: 1;
	transition: all 1s ease;
	background-color: rgba(0, 0, 0, 0.5);
}

.nav-menu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: absolute;
	right: -100%;
	opacity: 1;
	top: 66px;
	transition: all 0.8s ease;
	background: var(--color-background);
	padding: 20px 0;
	z-index: 1;
	border-radius: 0 0 14px 14px;
}
.nav-menu.active {
	right: 0;
	top: 66px;
	opacity: 1;
	z-index: 1;
	box-shadow: var(--shadow-dropdown);
	transition: right 0.6s ease, opacity 0.8s ease;
}

@media screen and (max-width: 950px) {
	.navbar-content {
		padding: 20px 20px;
	}

	.navbar-logo-corner {
		height: 26px;
		width: auto;
	}

	.navbar-button {
		font-size: 20px;
		width: 100%;
		text-align: center;
		margin: 20px;
	}

	.navbar-toggle {
		width: 26px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.navbar-toggle span {
		display: block;
		width: 100%;
		border-radius: 3px;
		height: 2px;
		background: var(--color-text-1);
		transition: all 0.3s;
		position: relative;
	}

	.navbar-toggle span + span {
		margin-top: 5px;
	}
	.navbar-toggle.active span:nth-child(1) {
		animation: ease 0.7s top forwards;
	}
	.navbar-toggle.not-active span:nth-child(1) {
		animation: ease 0.7s top-2 forwards;
	}
	.navbar-toggle.active span:nth-child(2) {
		animation: ease 0.7s scaled forwards;
	}
	.navbar-toggle.not-active span:nth-child(2) {
		animation: ease 0.7s scaled-2 forwards;
	}
	.navbar-toggle.active span:nth-child(3) {
		animation: ease 0.7s bottom forwards;
	}
	.navbar-toggle.not-active span:nth-child(3) {
		animation: ease 0.7s bottom-2 forwards;
	}
	@keyframes top {
		0% {
			top: 0;
			transform: rotate(0);
		}
		50% {
			top: 7px;
			transform: rotate(0);
		}
		100% {
			top: 7px;
			transform: rotate(45deg);
		}
	}
	@keyframes top-2 {
		0% {
			top: 7px;
			transform: rotate(45deg);
		}
		50% {
			top: 7px;
			transform: rotate(0deg);
		}
		100% {
			top: 0;
			transform: rotate(0deg);
		}
	}
	@keyframes bottom {
		0% {
			bottom: 0;
			transform: rotate(0);
		}
		50% {
			bottom: 7px;
			transform: rotate(0);
		}
		100% {
			bottom: 7px;
			transform: rotate(135deg);
		}
	}
	@keyframes bottom-2 {
		0% {
			bottom: 7px;
			transform: rotate(135deg);
		}
		50% {
			bottom: 7px;
			transform: rotate(0);
		}
		100% {
			bottom: 0;
			transform: rotate(0);
		}
	}
	@keyframes scaled {
		50% {
			transform: scale(0);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes scaled-2 {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
}

.section {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	/* ------------------------------------------------------   */
	/* border-bottom: 1px solid rgba(15, 50, 105, 0.03); */
	/* ------------------------------------------------------   */
}

@media screen and (max-width: 950px) {
	.section {
		width: 90%;
	}

	.section.home {
		width: 100%;
	}
}

.section.home {
	height: 99vh;
	position: relative;
}

.home-section-conent {
	width: 100%;
	height: auto;
	margin: 0;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home-link-mixing {
	font-size: 20px;
	font-weight: 700;
	color: rgb(75, 84, 255);
	padding: 0 10px;
	text-align: center;
	animation-delay: 2.2s;
	margin-top: 10px;
	margin-bottom: -10px;
}

.home-title {
	width: 100%;
	font-size: 80px;
	font-weight: 400;
	line-height: 1.2;
	text-align: center;
	letter-spacing: 1px;
	padding-top: 146px;
	word-spacing: 20px;
	color: rgba(0, 0, 0, 0.68);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

#ab1 {
	animation-delay: 0.4s;
	margin: 0 20px;
}
#ab2 {
	animation-delay: 0.8s;
	margin: 0 20px;
}
#ab3 {
	animation-delay: 1.2s;
	margin: 0 20px;
}
#ab4 {
	animation-delay: 1.6s;
	margin: 0 20px;
}
#ab5 {
	animation-delay: 2s;
	margin: 0 20px;
}

#ab1,
#ab2,
#ab3,
#ab4,
#ab5,
.home-link-mixing {
	opacity: 0;
	-webkit-animation-name: about-fade-in;
	animation-name: about-fade-in;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-transform: translateY(120px);
	transform: translateY(120px);
}
@-webkit-keyframes about-fade-in {
	0% {
		-webkit-transform: translateY(120px);
		transform: translateY(120px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes about-fade-in {
	0% {
		-webkit-transform: translateY(120px);
		transform: translateY(120px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.home-video {
	width: 100%;
	top: 0;
	height: 90%;
	object-fit: cover;
	-o-object-position: center center;
	object-position: center center;
	-o-object-fit: cover;
	position: absolute;
	z-index: -1;
	opacity: 0.55;
}

.home-social-section {
	width: auto;
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: auto;
}

.social-section-link {
	width: 30px;
	height: 30px;
	margin: 15px;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: var(--transition-main);
	transform: scale(1);
}
.social-section-link:hover {
	opacity: 0.5;
	transition: var(--transition-main);
	transform: scale(1.4);
}

.social-section-icon {
	width: 24px;
	height: 24px;
	fill: var(--color-text-1);
}

.social-section-icon.sonusvos {
	width: 32px;
	height: 32px;
}

@media screen and (max-width: 720px) {
	.section.home {
		height: calc(92vh + 80px);
		position: relative;
	}

	.home-section-conent {
		margin-top: auto;
	}

	.home-link-mixing {
		font-size: 16px;
		margin-top: 18px;
		margin-bottom: -18px;
	}

	.home-title {
		height: auto;
		font-size: 26px;

		padding-top: 8vh;
		text-align: center;

		line-height: 1.5;
		font-weight: 400;
		word-spacing: 0;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
	}
	#ab1 {
		margin: 20px;
	}
	#ab2 {
		margin: 20px;
	}
	#ab3 {
		margin: 20px;
	}
	#ab4 {
		margin: 20px;
	}
	#ab5 {
		margin: 20px;
	}

	.home-video {
		height: 92vh;
	}

	.home-social-section {
		height: 80px;
		padding: 0;
	}

	.social-section-link {
		margin: 1.5vw;
	}
	.social-section-link:hover {
		opacity: 1;
		transform: scale(1);
	}

	.social-section-icon {
		width: 22px;
		height: 22px;
	}

	.social-section-icon.sonusvos {
		width: 28px;
		height: 28px;
	}
}

.section.about {
	height: 790px;
	padding-bottom: 80px;
}

.section.mixing-mastering {
	height: 790px;
	padding-bottom: 80px;
	margin-top: 90px;
}

.about-content {
	width: 100%;
	max-width: 1100px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
}

.about-image-container {
	height: 88%;
	width: auto;
	position: absolute;
	z-index: 0;
}

.about-image {
	height: 100%;
	width: auto;
	border-radius: 50%;
	filter: brightness(1.01);

	transform: scale(1);
	animation: pulse 7s infinite;
}

.faded {
	opacity: 0.3;
}

@keyframes pulse {
	0% {
		transform: scale(0.94);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.94);
	}
}

.about-text-1 {
	width: 40%;
	font-size: var(--font-size-text);
	z-index: 1;
	position: absolute;
	top: 40px;
	left: 0;
	text-align: left;
}

.make-bold {
	letter-spacing: 2px;
	font-weight: 600;
	user-select: text;
}

.period {
	font-weight: 900;
	user-select: text;
}

.about-text-2 {
	width: 60%;
	font-size: var(--font-size-text);
	z-index: 1;
	position: absolute;
	bottom: 10px;
	right: 0;
	text-align: right;
}
.about-text-2 b {
	font-size: calc(var(--font-size-text) - 3px);
	font-family: 'Courier New', Courier, monospace;
	font-weight: 300;
}
.about-text-2 p {
	user-select: text;
	margin: 0;
	padding: 0;
	width: auto;
	height: auto;
	display: inline-block;
}

@media screen and (max-width: 720px) {
	.section.about {
		height: auto;
		margin-top: 20px;
		padding-bottom: 20px;
	}

	.section.mixing-mastering {
		height: auto;
		padding-bottom: 150px;
		margin-top: 80px;
	}

	.about-content {
		height: auto;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		margin: 0;
	}

	.about-image-container {
		height: auto;
		width: 100%;
		position: relative;
	}

	.about-image {
		width: 100%;
		border-radius: 0;
		animation: none;
	}

	.faded {
		opacity: 0.6;
	}

	.about-text-1 {
		width: 100%;
		font-size: var(--font-size-text);
		text-align: left;
		position: relative;
		margin-bottom: 20px;
		top: 0px;
	}

	.about-text-2 {
		width: 100%;
		font-size: var(--font-size-text);
		text-align: left;
		position: relative;
		margin-top: 10px;
		bottom: 0px;
	}
}

.section.music {
	height: 790px;
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.music-planet {
	height: auto;
	position: absolute;
}
.music-planet.mp1 {
	width: 150px;
	top: -50px;
	left: 250px;
	filter: drop-shadow(var(--shadow-planet)) blur(0.6px) opacity(0.3);
}
.music-planet.mp2 {
	width: 300px;
	top: 100px;
	left: -150px;
	filter: drop-shadow(var(--shadow-planet)) opacity(0.5);
}
.music-planet.mp3 {
	width: 600px;
	bottom: -300px;
	right: -150px;
	filter: drop-shadow(var(--shadow-planet)) brightness(1.09) opacity(0.7);
}
.music-planet.mp4 {
	width: 800px;
	bottom: -1600px;
	left: -600px;
	filter: drop-shadow(var(--shadow-planet)) brightness(1.06) opacity(0.7);
}

.music-scplayer {
	width: 88%;
	max-width: 800px;
	box-shadow: var(--shadow-1);
	padding: 20px;
	background-color: var(--color-background);
	border-radius: 20px;
	padding-bottom: 8px;
}

.music-tabs-row {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.music-tab-button {
	width: 49%;
	padding: 11px 10px 10px 10px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(238, 240, 244);
	border-radius: 8px;
	font-weight: 600;
	font-size: 16px;
	opacity: 1;
	transition: all 0.4s ease-in-out;
}
.music-tab-button:hover {
	opacity: 0.5;
	transition: all 0.4s ease-in-out;
}

.music-tab-button.music-tab-enabled {
	background-color: rgb(253, 117, 117);
	color: white;
}

.music-scplayer-button-row {
	width: 100%;
	max-width: 800px;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.music-scplayer-button-row span {
	padding-left: 10px;
	font-size: 14px;
	line-height: 1;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 600;
	margin-right: 4px;
	margin-top: 2px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.music-arrow {
	width: 22px;
	height: 22px;
	margin-left: 10px;
}

.music-scplayer-button {
	width: 28px;
	height: auto;
	fill: var(--color-text-1);
	cursor: pointer;
	border-radius: 50%;
	margin: 10px 10px 10px 10px;
	transition: var(--transition-main);
	opacity: 1;
	box-sizing: border-box;
}
.music-scplayer-button:hover {
	opacity: 0.5;
	transition: var(--transition-main);
}

@media screen and (max-width: 720px) {
	.section.music {
		height: auto;
		padding: 0;
		margin-top: 80px;
	}

	.music-scplayer {
		width: 98%;
		padding: 8px;
		border-radius: 10px;
		padding-bottom: 2px;
	}

	.music-scplayer-button-row span {
		font-size: 14px;
		margin-right: 5px;
		margin-top: -5px;
	}

	.music-arrow {
		width: 18px;
		height: 18px;
		margin-left: 10px;
	}

	.music-tabs-row {
		margin-bottom: 6px;
	}

	.music-tab-button {
		width: 49%;
		padding: 7px 10px 6px 10px;
		font-size: 14px;
	}

	.music-scplayer-button {
		width: 28px;
		margin: 4px 10px 10px 10px;
	}
	.music-scplayer-button:hover {
		opacity: 1;
	}
}

.section.highlights {
	min-height: 100vh;
	padding-bottom: 131px;
}

.highlights-container {
	width: 100%;
	max-width: 1100px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 56px;
}

.highlights-text-1 {
	width: 48%;
	font-size: var(--font-size-text);
	z-index: 1;
	text-align: left;
}

.highlights-yt-embed {
	margin-top: 10px;
	width: 45%;
	aspect-ratio: 16 / 9;
	padding: 12px;
	background-color: var(--color-background);
	border-radius: 38px;
	box-shadow: var(--shadow-1);
}

.highlights-sync-gallery {
	height: auto;
	width: 45%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 12px 12px 0 12px;
	background-color: var(--color-background);
	border-radius: 38px;
	box-shadow: var(--shadow-1);
}

.highlights-sync-image {
	width: 30%;
	border-radius: 28px;
	margin-bottom: 12px;
}

.sync-filler {
	width: 5%;
}

@media screen and (max-width: 720px) {
	.section.highlights {
		padding: 0;
		margin-top: 130px;
	}

	.highlights-container {
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 50px;
		margin-top: 0;
	}

	.highlights-text-1 {
		width: 100%;
	}

	.highlights-yt-embed {
		margin-top: 20px;
		width: 98%;
		padding: 8px;
		border-radius: 10px;
	}

	.highlights-sync-gallery {
		width: 98%;
		display: flex;
		padding: 8px 8px 0 8px;
		border-radius: 10px;
		margin-top: 20px;
	}

	.highlights-sync-image {
		width: 30%;
		border-radius: 10px;
		margin-bottom: 8px;
	}
}

.section.music-video {
	height: auto;
	padding-bottom: 80px;
}

.music-video-yt-embed {
	width: 92%;
	max-width: 1100px;
	aspect-ratio: 16 / 9;
	padding: 12px;
	background-color: var(--color-background);
	border-radius: 38px;
	box-shadow: var(--shadow-1);
}

@media screen and (max-width: 720px) {
	.section.music-video {
		height: auto;
		padding: 0;
		margin-top: 80px;
	}

	.music-video-yt-embed {
		width: 98%;
		padding: 8px;
		border-radius: 10px;
	}
}

.section.gallery {
	height: 800px;
	padding-bottom: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.gallery-container {
	height: auto;
	width: 90%;
	max-width: 1100px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.gallery-image {
	width: 24%;
	margin-bottom: 12px;
	transform: scale(1);
	transition: var(--transition-long);
	z-index: 0;
}
.gallery-image:hover {
	transform: scale(1.112);
	transition: var(--transition-long);
	z-index: 1;
	box-shadow: var(--shadow-dropdown);
	cursor: pointer;
}

@media screen and (max-width: 720px) {
	.section.gallery {
		height: auto;
		padding: 0;
		margin-top: 150px;
	}

	.gallery-container {
		height: auto;
		width: 100%;
	}

	.gallery-image {
		width: 49%;
		margin-bottom: 8px;
	}
	.gallery-image:hover {
		transform: scale(1);
		box-shadow: none;
	}
}

.section.contact {
	height: 370px;
	padding-top: 87px;
}

.contact-email {
	font-size: 30px;
	margin-top: -10px;
	font-weight: 300;
	color: var(--color-text-1);
	letter-spacing: 4px;
	-webkit-touch-callout: none;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	-webkit-tap-highlight-color: var(--color-text-link-light);
	padding: 18px 90px;
	background-color: var(--color-background);
	border-radius: 50px;
	box-shadow: var(--shadow-small);
}

@media screen and (max-width: 720px) {
	.section.contact {
		height: auto;
		padding: 0;
		margin-top: 100px;
	}

	.contact-email {
		font-size: 16px;
		margin-top: -2px;
		padding: 10px 24px;
		border-radius: 50px;
		letter-spacing: 3px;
	}
}

.footer {
	width: 100%;
	height: 434px;
	position: relative;
}

.footer-image {
	height: 100%;
	width: auto;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 0;
	padding-right: 8px;
}

.footer-text {
	font-family: 'Courier New', Courier, monospace;
	font-size: 16px;
	position: absolute;
	left: 50%;
	bottom: 60px;
	transform: translate(-50%, 0);
	z-index: 1;
	text-align: center;
}

.ymir-icon {
	height: 54px;
	width: 54px;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0);
	z-index: 1;
	object-fit: scale-down;
	margin-left: 0px;
}

.hey {
	font-size: 12px;
	color: var(--color-text-1);
	position: absolute;
	left: 50%;
	bottom: 37px;
	z-index: 1;
	transform: translate(-50%, 0);
	padding-left: 43px;
	font-weight: 400;
}

.footer-quote {
	font-size: 18px;
	position: absolute;
	left: 50%;
	top: 60px;
	transform: translate(-120%, 0);
	z-index: 1;
	letter-spacing: 1px;
	text-align: center;
	font-weight: 600;
}
.footer-quote span {
	font-size: 14px;
	font-weight: 500;
}

@media screen and (max-width: 720px) {
	.footer {
		width: 100%;
		height: 434px;
		position: relative;
		margin-top: 40px;
	}

	.footer-text {
		width: 100%;
		font-size: 12px;
		bottom: 69px;
	}

	.ymir-icon {
		height: 43px;
		width: 43px;
		bottom: 17px;
		margin-left: -1px;
	}
	.hey {
		font-size: 8px;
		bottom: 47px;
		padding-left: 30px;
	}

	.footer-quote {
		width: 100%;
		font-size: 16px;
		position: absolute;
		left: 50%;
		top: 52%;
		transform: translate(-50%, 0);
		z-index: 1;
		text-align: center;
	}
	.footer-quote span {
		font-size: 10px;
	}
}

.gallery-modal-backdrop {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
	backdrop-filter: blur(1px);
}

.gallery-modal-content {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--color-background);
	width: auto;
	height: auto;
	border-radius: 4px;
	box-shadow: var(--shadow-dropdown);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	padding: 20px;
}

.MODAL_STYLES,
.BACKDROP_STYLE {
	opacity: 0;
	-webkit-animation-name: modal-fade;
	animation-name: modal-fade;
	-webkit-animation-duration: 200ms;
	animation-duration: 200ms;
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
@-webkit-keyframes modal-fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes modal-fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.gallery-modal-image {
	width: 38vw;
	height: 38vw;
	max-height: 730px;
	min-height: 500px;
	max-width: 730px;
	min-width: 500px;
	border-radius: 4px;
	box-shadow: var(--shadow-1);
}

.gallery-modal-info {
	width: 25vw;
	height: 38vw;
	max-height: 730px;
	min-height: 500px;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-left: 30px;
	position: relative;
}

.gallery-modal-exit {
	width: 22px;
	height: 22px;
	fill: var(--color-text-1);
	transition: var(--transition-main);
	opacity: 1;
	cursor: pointer;
	position: absolute;
	right: 8px;
	top: 8px;
}
.gallery-modal-exit:hover {
	transition: var(--transition-main);
	opacity: 0.5;
}

.gallery-modal-title {
	width: 80%;
	height: auto;
	text-align: left;
	font-size: var(--font-size-title);
	line-height: 1;
	letter-spacing: 1px;
	word-spacing: 4px;
	margin-top: 30%;
}

.gallery-modal-desc {
	width: 80%;
	font-size: var(--font-size-text);
	line-height: 1.6;
	text-align: left;
	margin-top: 30px;
}

.gallery-modal-arrows {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
	padding-bottom: 8px;
}

.gallery-modal-arrow {
	width: 28px;
	height: auto;
	fill: var(--color-text-1);
	cursor: pointer;
	border-radius: 50%;
	box-shadow: var(--shadow-planet);
	padding: 10px;
	background-color: var(--color-background);
	transition: var(--transition-main);
}
.gallery-modal-arrow:hover {
	fill: var(--color-text-link);
	transition: var(--transition-main);
}

@media screen and (max-width: 820px) {
	.gallery-modal-content {
		width: 79vw;
		min-width: 0px;
		min-height: 0px;
		max-width: 450px;
		padding: 10px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.gallery-modal-image {
		width: 79vw;
		height: 79vw;
		min-width: 0px;
		min-height: 0px;
		max-width: 450px;
		max-height: 450px;
	}

	.gallery-modal-info {
		width: 79vw;
		min-width: 0px;
		min-height: 0px;
		max-width: 450px;
		height: 27vh;
		max-height: 170px;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 10px;
		padding: 10px;
		box-sizing: border-box;
	}

	.gallery-modal-exit {
		width: 22px;
		height: 22px;
		fill: var(--color-background);
		transition: var(--transition-main);
		opacity: 1;
		cursor: pointer;
		position: absolute;
		right: -10px;
		top: calc(-79vw - 50px);
	}

	.gallery-modal-title {
		width: auto;
		font-size: 20px;
		margin-top: 0;
	}

	.gallery-modal-desc {
		width: auto;
		font-size: 12px;
		text-align: left;
		margin-top: 10px;
	}

	.gallery-modal-arrows {
		padding-bottom: 0px;
	}

	.gallery-modal-arrow {
		width: 22px;
		padding: 10px;
	}
	.gallery-modal-arrow:hover {
		fill: var(--color-text-1);
	}
}
